<template>
  <base-layout>
    <ion-content class="has-header has-subheader" id="wcpt-sessiontypes">
      <div class="content">
        <div class="sessiontype-wrapper">
          <template  v-for="type in types"  :key="type.id">
            <router-link
                class="sessiontype-item"
                :to="'/app/sessiontypes/' + type.id"
                :style="{'background-color': type.color, 'color': type.font_color}"
                v-if="type.name && type.name != 'Breaks'"
            >
              <span class="text" :style="{'color': type.font_color}">{{ type.name || 'Other' }}</span>
              <span class="count" :style="{'color': type.font_color}">{{ type.count }}</span>
            </router-link>
          </template>

        </div>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      types: []
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("sessiontypes", ["getSessionTypes"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),


  },
  async created() {
    this.types = await this.getSessionTypes();
    this.trackWithLabel('Session Types')


  }
};
</script>
